
<template>
  <div class="matrix-table part-change-table">
    <el-table 
    class="headHide"
    :data="tableData"	border
     style="width: 100%">
      <template v-for="(col ,index) in cols">
        <!-- 第一列 标题 -->
        <el-table-column 
        fixed
        class-name="flow-node-table-label"
        width='150px'
        :prop="col['addData']"
        :label="col.label"
         v-if="!index">
          <template slot-scope="scope">
            {{scope.row.name}}
          </template>
        </el-table-column>
        <!-- 中间主体 -->
        <el-table-column
        :prop="col['addData'+index]"
        width="150px"
        :label="col.label"
         v-else>
          <template slot-scope="scope">
            <span v-if="scope.row.name!='操作'">
              <el-input v-if="scope.row.xtype=='input'" placeholder="请输入" v-model="scope.row['addData'][index-1]"></el-input>
 
              <el-select v-else-if="scope.row.xtype=='select'" 
                v-model="scope.row['addData'][index-1]">
                <el-option v-for="item in scope.row.selectDetail" 
                  :key="item" 
                  :label="item" 
                  :value="item">
                </el-option>
              </el-select>
 
              <template v-else>{{scope.row['addData'][index-1]}}</template>
            </span>
            <!-- 操作 -->
            <span v-else>
              <el-button @click="delCol(('addData'+index),index)">
                删除
              </el-button>
            </span>
          </template>
        </el-table-column>
      </template>
      <el-table-column
      label="添加列"
      style='min-width:80px;'>
        <template slot-scope="scope" v-if="scope.$index==0">
          <el-button raw-type="button" @click="addCol">
            +
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <!-- <el-button raw-type="button" @click="addCol">
      添加一列
    </el-button> -->
    
    {{cols}}<p>{{tableData}}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio: 0,
      loading: false,
      tableData: [],
      cols: [
        {prop: '', label: '标题'},
        {prop: 'addData1', label: 'add1'},
      ]
    };
  },
  created() {
    this.tableData=[{
          name: '品牌',
          addData: ['123'],
          xtype:'input',
          selectDetail:['1','2']
        },{
          name: '物料型号',
          addData: ['234'],
          xtype:'select',
          selectDetail:['1','2']
        },{
          name: '评审完成时间',
          addData: ['5'],
          xtype:'date'
        },{
          name: '操作',
          addData: ['-'],
          xtype:'',
          selectDetail:['1','2']
        }]
  },
  mounted() {
  },
  methods: {
    addCol(){
      // {prop: 'addData'+this.cols.length, label: 'add'+this.cols.length}
        this.cols.push({prop: 'addData',label: 'add'})
        this.tableData.forEach(item=>{
          item['addData'].push('')
        })
    },
    delCol(name,index){
      this.cols.splice(index,1);
      this.tableData.forEach(item=>{
        if(item['addData'])
        item['addData'].splice(index-1,1);
      })
      // this.tableData.forEach(item=>{
      //   debugger
      //   if(item.hasOwnProperty(name)){
      //     this.$delete(item,name)
      //   }
      // })
    },
    //获取动态表格列名
    //表头列宽度
    flexColumnWidth: function(colId) {
      let flexWidth = 0;
      switch (colId) {
        case "desEn":
          flexWidth = 170;
          break;
        default:
          flexWidth = 150;
      }
      return flexWidth + "px";
    },
    //表格行号切换
    // showRow(row, event, column) {
    //   this.radio = this.objectValue[this.object.id].indexOf(row);
    // }
  }
};
</script>
 
<style lang="scss">
.matrix-table {
  tbody .flow-node-table-label{
    padding: 3px 12px 3px 20px;
    width: 150px;
    background: #f3faff !important;
    color: #798999;
    position: relative;
    background-clip: padding-box;
    line-height: 1.2;
  }
  .hover-row{
    td{
      &:not(.flow-node-table-label){
        background: #fff !important;
      }
    }
  }
  .headHide{
    .el-table__header-wrapper{
      display:none;
    }
  }
}
</style>